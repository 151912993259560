import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import ChatIcon from '@roborobo/ui/icons/ChatIcon';
import useGetConfig from '@roborobo/contexts/config/useGetConfig';
import { Button } from '@chakra-ui/react';

const enum AnimState {
  OPEN = 'open',
  CLOSE = 'close',
}

const ChatButton = ({
  isOpen,
  isDesktop,
  onOpenDialog,
}: {
  isOpen: boolean;
  isDesktop: boolean;
  onOpenDialog: () => void;
}) => {
  const [inited, setInited] = useState<boolean>(isOpen);
  const controls = useAnimation();
  const { appearance, settings } = useGetConfig();

  const bottom = settings?.position?.bottom || '20px';
  const right = settings?.position?.right || '20px';

  const ANIMATIONS = {
    [AnimState.OPEN]: () => ({
      right: '-120px',
    }),
    [AnimState.CLOSE]: () => ({
      right: right,
    }),
  };

  useEffect(() => {
    if (!inited) {
      setTimeout(
        () => controls.start(AnimState.CLOSE).then(() => setInited(true)),
        400,
      );
    } else {
      controls.start(isOpen ? AnimState.OPEN : AnimState.CLOSE);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inited, isOpen]);

  return (
    <Button
      as={motion.button}
      variants={ANIMATIONS}
      animate={controls}
      transition="0.3s ease-out"
      variant="unstyled"
      onClick={onOpenDialog}
      w="52px"
      h="52px"
      bgColor={appearance.secondaryColor}
      borderRadius="100%"
      border="none"
      outline="transparent solid 2px"
      position="fixed"
      right="-120px"
      bottom={bottom}
      display="inline"
      appearance="none"
      cursor="pointer"
    >
      <ChatIcon
        width="24px"
        height="24px"
        color={appearance.buttonIconColor}
        mt="1px"
      />
    </Button>
  );
};

export default ChatButton;
