import React, { useContext, useEffect, useState } from 'react';
import { WidgetConfig } from '@roborobo/types';
import ConfigContext from '@roborobo/contexts/config/ConfigContext';

const ConfigProvider = ({
  config,
  children,
}: {
  config: WidgetConfig;
  children?: React.ReactNode;
}) => {
  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>(config);
  useContext(ConfigContext);

  useEffect(() => {
    const isValidPosition = (bottom: number, right: number): boolean => {
      return bottom >= 0 && right >= 0 && right <= 50 && bottom <= 300;
    };

    const updatePosition = (event: any) => {
      const { bottom, right } = event.detail;
      if (!isValidPosition(bottom, right)) {
        console.error('[RoboRoboChatWidget]: Invalid position!');
        return;
      }

      if (
        bottom !== widgetConfig?.settings?.position?.bottom ||
        right !== widgetConfig?.settings?.position?.right
      ) {
        setWidgetConfig((prevConfig) => ({
          ...prevConfig,
          settings: {
            ...prevConfig.settings,
            position: {
              bottom,
              right,
            },
          },
        }));
        console.info('[RoboRoboChatWidget]: Position updated!');
      }
    };

    document.addEventListener('roborobo.updatePosition', updatePosition);

    return () => {
      document.removeEventListener('roborobo.updatePosition', updatePosition);
    };
  }, [widgetConfig?.settings?.position]);

  return (
    <ConfigContext.Provider value={widgetConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
