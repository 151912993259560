import React, { useCallback, useEffect, useRef } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import ChatDialogFrame from '@roborobo/components/ChatDialogFrame';
import ChatButton from '@roborobo/components/ChatButton';
import { setItemWithExpiry } from '@roborobo/lib/localStorage/localStorage';
import { CHAT_HAS_VISITED_KEY } from '@roborobo/lib/localStorage/constants';
import postRobot from 'post-robot';
import useGetConfig from '@roborobo/contexts/config/useGetConfig';
import { useMedia, useWindowSize } from 'react-use';
import {
  getWidgetDialogDefaultStatus,
  getWidgetSize,
  WIDGET_Z_INDEX,
} from '@roborobo/components/utils';
import { Position } from '@roborobo/types';

const ChatWidget = () => {
  const frameRef: React.Ref<HTMLIFrameElement> | null = useRef(null);
  const config = useGetConfig();
  const { isOpen, onToggle, onOpen } = useDisclosure({
    defaultIsOpen: getWidgetDialogDefaultStatus(config),
  });

  const isDesktop = useMedia('(min-width: 48em)');
  const isFullScreenMode = config?.settings?.fullScreenMode || false;
  const bottomPosition = config?.settings?.position?.bottom || 0;

  const { width: windowW, height: windowH } = useWindowSize();
  const { width, height } = getWidgetSize(
    windowW,
    windowH,
    isFullScreenMode,
    isDesktop,
    bottomPosition,
  );

  let position: Position & { left?: number } = {
    bottom: config?.settings?.position?.bottom || 20,
    right: config?.settings?.position?.right || 20,
  };

  if (isOpen && (isFullScreenMode || !isDesktop)) {
    position = {
      left: 0,
      right: 0,
      bottom: 0,
    };
  }

  const toggleBodyOverflow = useCallback(
    (isOpen: boolean) => {
      const body = document.querySelector('body');
      if (body) {
        body.style.overflow = '';
        if (isOpen && !isDesktop) {
          body.style.overflow = 'hidden';
        }
      }
    },
    [isDesktop],
  );

  useEffect(() => {
    setItemWithExpiry(CHAT_HAS_VISITED_KEY, isOpen);
  }, [isOpen]);

  useEffect(() => {
    toggleBodyOverflow(isOpen);
  }, [isOpen, isDesktop, toggleBodyOverflow]);

  useEffect(() => {
    if (frameRef.current === null) {
      return;
    }

    const frameSrcDomain = new URL(config.frameSrc).origin;
    const listener = postRobot.on(
      'rb-chatwidget-message',
      {
        domain: frameSrcDomain,
        window: frameRef.current?.contentWindow,
      },
      ({ data }): any => {
        const { type } = data;
        if (type === 'toggle') {
          onToggle();
        } else if (type === 'getConfig') {
          return config;
        }
      },
    );

    return () => {
      listener.cancel();
    };
  }, [onToggle, frameRef, config]);

  return (
    <Flex
      position="fixed"
      top="auto"
      zIndex={WIDGET_Z_INDEX}
      flexFlow="column"
      alignItems="flex-end"
      color="#718096"
      fontSize="16px"
      sx={position}
      width={isOpen ? width : '52px'}
      height={isOpen ? height : '52px'}
    >
      <ChatDialogFrame
        isOpen={isOpen}
        isDesktop={isDesktop}
        frameRef={frameRef}
        isFullScreenMode={isFullScreenMode}
        bottomPosition={bottomPosition}
      />
      {!config?.settings?.fullScreenMode && (
        <ChatButton
          isOpen={isOpen}
          isDesktop={isDesktop}
          onOpenDialog={onOpen}
        />
      )}
    </Flex>
  );
};

export default ChatWidget;
