import { WidgetConfig } from '@roborobo/types';
import { getItemWithExpiry } from '@roborobo/lib/localStorage/localStorage';
import { CHAT_HAS_VISITED_KEY } from '@roborobo/lib/localStorage/constants';

export const WIDGET_Z_INDEX = 10000;

export const getWidgetDialogDefaultStatus = (config: WidgetConfig): boolean => {
  if (config.settings.fullScreenMode) {
    return true;
  }

  return getItemWithExpiry(CHAT_HAS_VISITED_KEY) || false;
};

export const getWidgetSize = (
  windowW: number,
  windowH: number,
  isFullScreenMode: boolean,
  isDesktop: boolean,
  bottomPosition: number,
) => {
  let height = 582;

  if (!isFullScreenMode && isDesktop) {
    const spacing = bottomPosition + 20;
    if (height + spacing >= windowH) {
      height = windowH - spacing;
    }

    return {
      width: 360,
      height,
    };
  }

  return {
    width: windowW,
    height: windowH,
  };
};
