import React from 'react';
import ChatWidget from '@roborobo/components/ChatWidget';
import ConfigProvider from '@roborobo/contexts/config/ConfigProvider';
import { WidgetConfig } from '@roborobo/types';

const App = ({ config }: { config: WidgetConfig }) => {
  return (
    <ConfigProvider config={config}>
      <ChatWidget />
    </ConfigProvider>
  );
};

export default App;
