import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@roborobo/App';
import { Position, Settings, WidgetConfig } from '@roborobo/types';

// Import correct config files before build the embed script
import defaultConfig from '@roborobo/config/decathlon.json';

window.RoboRoboChatWidgetSDK = (function () {
  const init = (config?: Settings) => {
    const root = ReactDOM.createRoot(
      document.getElementById('roborobo-chatwidget-root') as HTMLElement,
      { identifierPrefix: 'roborobo' },
    );

    const widgetConfig: WidgetConfig = {
      ...defaultConfig,
      settings: {
        ...defaultConfig.settings,
        ...(config || {}),
      },
    };

    root.render(<App config={widgetConfig} />);
  };

  const show = () => {
    const root = document.getElementById('roborobo-chatwidget-root');
    if (root) root.style.display = 'initial';
  };

  const hide = () => {
    const root = document.getElementById('roborobo-chatwidget-root');
    if (root) root.style.display = 'none';
  };

  const updatePosition = (position: Position) => {
    document.dispatchEvent(
      new CustomEvent('roborobo.updatePosition', {
        detail: position,
      }),
    );
  };

  return {
    init,
    show,
    hide,
    updatePosition,
  };
})();
