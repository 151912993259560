import React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import { useWindowSize } from 'react-use';
import useGetConfig from '@roborobo/contexts/config/useGetConfig';
import { getWidgetSize } from '@roborobo/components/utils';

const roundedStyle: any = {
  '@media screen and (min-width: 48em)': {
    borderRadius: '18px',
    boxShadow:
      '10px 10px 40px rgba(0, 0, 0, 0.08), 5px 14px 80px rgba(26, 26, 26, 0.12)',
  },
};

const ChatDialogFrame = ({
  isOpen,
  isDesktop,
  isFullScreenMode = false,
  frameRef,
  bottomPosition,
}: {
  isOpen: boolean;
  isDesktop: boolean;
  isFullScreenMode?: boolean;
  frameRef: React.Ref<HTMLIFrameElement>;
  bottomPosition: number;
}) => {
  const { frameSrc } = useGetConfig();
  const { width: windowW, height: windowH } = useWindowSize();
  const { width, height } = getWidgetSize(
    windowW,
    windowH,
    isFullScreenMode,
    isDesktop,
    bottomPosition,
  );

  const ANIMATIONS = {
    open: () => ({
      width: width,
      height: height,
      opacity: 1,
      display: 'block',
    }),
    close: () => ({
      width: '52px',
      height: '52px',
      opacity: 0,
      display: 'none',
    }),
  };

  return (
    <Box
      ref={frameRef}
      as={motion.iframe}
      src={frameSrc}
      animate={isOpen ? 'open' : 'close'}
      variants={ANIMATIONS}
      opacity={0}
      overflow="hidden"
      position="absolute"
      border="none"
      borderRadius="0"
      boxShadow="none"
      scrolling="no"
      display={isOpen ? 'block' : 'none'}
      sx={{
        bottom: '0',
        right: '0',
        ...(!isFullScreenMode ? roundedStyle : {}),
      }}
    />
  );
};

export default ChatDialogFrame;
