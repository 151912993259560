import { useContext } from 'react';
import ConfigContext from '@roborobo/contexts/config/ConfigContext';
import { WidgetConfig } from '@roborobo/types';

const useGetConfig = (): WidgetConfig => {
  const context = useContext(ConfigContext);

  return {
    ...context,
  };
};

export default useGetConfig;
