import React from 'react';
import { Icon } from '@chakra-ui/react';

const ChatIcon = (props: any): JSX.Element => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21 0H3C1.35938 0 0 1.35854 0 2.99816V16.4899C0 18.1764 1.35938 19.4881 3 19.4881H9.51562L15.3281 23.8448C15.5625 24.0322 15.8438 24.0322 16.125 23.9385C16.3594 23.7979 16.5 23.5637 16.5 23.2358V19.4881H21C22.6875 19.4881 24 18.1764 24 16.4899V2.99816C24 1.35854 22.6875 0 21 0Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChatIcon;
