const DEFAULT_TTL = 86400000;

export const setItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (_) {
    return;
  }
};

export const getItem = (key: string): any => {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    return JSON.parse(itemStr);
  } catch (_) {
    return;
  }
};

export const setItemWithExpiry = (
  key: string,
  value: any,
  ttl: number = DEFAULT_TTL,
) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key: string): any => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};
